<template>
    <div class="logo">
        <div class="image">
            <img src="@/img/euromall.png"/>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-logo",

  props: {
    logo: {
      default: false,
    }
  }
}
</script>

<style scoped lang="scss">

.logo {
    //text-align: center;

    img {
        height: 2em;
        //margin-bottom: 0.3em;
    }

    div {
        line-height: 1em;
        font-size: 1em;
    }

    .name {
        font-weight: 700;
        color: #222;
        letter-spacing: 0.02em;

        .top {
            font-size: 0.7em;
            margin-bottom: 0.3em;
            font-weight: 700;
        }

        .bottom {
            font-size: 1.1em;
            text-transform: uppercase;
        }
    }
}


</style>